import { Card, Table } from 'antd';
import React from 'react';
import { Cell, Pie, PieChart } from 'recharts';

const dynamicColor = () => {
  var r = Math.floor(Math.random() * 255);
  var g = Math.floor(Math.random() * 255);
  var b = Math.floor(Math.random() * 255);
  return 'rgb(' + r + ',' + g + ',' + b + ')';
};

const stringToNumber = (str, max) => {
  let sum = 0;
  for (let i = 0; i < str.length; i++) {
    sum += str.charCodeAt(i);
  }
  return (sum % max) + 1;
};

function getRandomColor(str) {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(stringToNumber(str, 16))];
  }
  return color;
}

const renderLabel = ({ name }) => {
  return name;
};

const CardPayPerType = ({ pay_per_type, pay_per_method }) => {
  if (pay_per_type === undefined || pay_per_method === undefined) {
    return null;
  }
  return (
    <div className='site-card-border-less-wrapper'>
      <Card
        title='Payment method preference'
        bordered={true}
        style={{
          width: 650,
        }}
      >
        <PieChart width={630} height={260}>
          <Pie
            data={pay_per_type}
            dataKey='value'
            cx='25%'
            cy='50%'
            fill='#8884d8'
            legendType='line'
            label={renderLabel}
          >
            {pay_per_type.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={getRandomColor(entry.name)} />
            ))}
          </Pie>
          <Pie
            data={pay_per_method}
            dataKey='value'
            cx='75%'
            cy='50%'
            fill='#82ca9d'
            legendType='line'
            label={renderLabel}
          >
            {pay_per_method.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={getRandomColor(entry.name)} />
            ))}
          </Pie>
        </PieChart>
      </Card>
    </div>
  );
};

export default CardPayPerType;
