import { Card, Table } from 'antd';
import React from 'react';
import { Username } from '../fragments/Username.jsx';

const CardRecentUsers = ({ events }) => {
  const columns = [
    {
      title: 'User',
      dataIndex: 'username',
      key: 'username',
      render: (text) => <Username user={text} />,
    },
    {
      title: 'No. of actions',
      dataIndex: 'count_events',
      key: 'count_events',
    },
    {
      title: 'Minutes since last action',
      dataIndex: 'minutes_since_last_event',
      key: 'minutes_since_last_event',
    },
    {
      title: 'User ID',
      dataIndex: 'user_id',
      key: 'user_id',
    },
    {
      title: 'Language code',
      dataIndex: 'lang',
      key: 'lang',
    },
  ];

  return (
    <div className='site-card-border-less-wrapper'>
      <Card
        title='Recent users (last 30 minutes)'
        bordered={true}
        style={{
          width: 650,
        }}
      >
        <Table
          columns={columns}
          dataSource={events}
          rowKey={(r) => r.user_id}
          pagination={false}
        />
      </Card>
    </div>
  );
};

export default CardRecentUsers;
