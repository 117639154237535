import './styles.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Anchor, Row, Col, Layout, FloatButton } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import CardPerHour from './charts/CardPerHour';
import CardPerDay from './charts/CardPerDay';
import CardPerModel from './charts/CardPerModel';
import CardPerFilter from './charts/CardPerFilter';
import CardTopUsers from './charts/CardTopUsers';
import CardRecentUsers from './charts/CardRecentUsers';
import CardPayPerUser from './charts/CardPayPerUser';
import CardTopInfo from './charts/CardTopInfo';
import CardPayPerType from './charts/CardPayPerType';
import CardLastDays from './charts/CardLastDays';

const { Header, Content, Footer } = Layout;

//const BACKEND_URL = 'http://localhost:3001';
const BACKEND_URL = 'https://stat.drmr.eu';

function App() {
  const [state, setState] = useState({
    countsPerHour: [],
    countsPerDay: [],
    countsPerModel: [],
    countsPerFilter: [],
    topUsers: [],
    recentUsers: [],
    payPerUser: [],
    recentAction: [],
    responseTime: [],
    payPerType: [],
    payPerMethod: [],
    lastDays: [],
  });

  useEffect(() => {
    axios.defaults.baseURL = BACKEND_URL;
    const s = {};
    Promise.all([
      axios.get(`/count_per_hour`),
      axios.get(`/count_per_day`),
      axios.get(`/count_per_model`),
      axios.get(`/count_per_filter`),
      axios.get(`/top_users`),
      axios.get(`/recent_users`),
      axios.get(`/pay_per_user`),
      axios.get(`/recent_action`),
      axios.get(`/response_time`),
      axios.get(`/pay_per_type`),
      axios.get(`/pay_per_method`),
      axios.get(`/last_days`),
    ]).then(
      ([
        res1,
        res2,
        res3,
        res4,
        res5,
        res6,
        res7,
        res8,
        res9,
        res10,
        res11,
        res12,
      ]) => {
        setState({
          countsPerHour: res1.data,
          countsPerDay: res2.data,
          countsPerModel: res3.data,
          countsPerFilter: res4.data,
          topUsers: res5.data,
          recentUsers: res6.data,
          payPerUser: res7.data,
          recentAction: res8.data,
          responseTime: res9.data,
          payPerType: res10.data,
          payPerMethod: res11.data,
          lastDays: res12.data,
        });
      }
    );
  }, []);

  return (
    <BrowserRouter>
      <Layout id='container'>
        <Content>
          <Row>
            <Col span={5}>
              <Anchor
                items={[
                  {
                    key: 'card-last-days',
                    href: '#card-last-days',
                    title: "Last days' stats",
                  },
                  {
                    key: 'card-per-day',
                    href: '#card-per-day',
                    title: 'Traffic per day',
                  },
                  {
                    key: 'card-per-hour',
                    href: '#card-per-hour',
                    title: 'Traffic during the day',
                  },
                  {
                    key: 'card-per-model',
                    href: '#card-per-model',
                    title: 'Model preference',
                  },
                  {
                    key: 'card-per-filter',
                    href: '#card-per-filter',
                    title: 'Filter usage',
                  },
                  {
                    key: 'card-top-users',
                    href: '#card-top-users',
                    title: 'Top 10 active users',
                  },
                  {
                    key: 'card-recent-users',
                    href: '#card-recent-users',
                    title: 'Recent users (last 30 minutes)',
                  },
                ]}
              />
            </Col>
            <Col span={16}>
              <div className='logo' />
              <div id='card-top-info'>
                <CardTopInfo
                  recentAction={state.recentAction}
                  responseTime={state.responseTime}
                />
              </div>
              <div id='card-last-days'>
                <CardLastDays lastDays={state.lastDays} />
              </div>
              <div id='card-per-day'>
                <CardPerDay events={state.countsPerDay} />
              </div>
              <div id='card-per-hour'>
                <CardPerHour events={state.countsPerHour} />
              </div>
              <div id='card-per-model'>
                <CardPerModel events={state.countsPerModel} />
              </div>
              <div id='card-per-filter'>
                <CardPerFilter events={state.countsPerFilter} />
              </div>
              <div id='card-top-users'>
                <CardTopUsers events={state.topUsers} />
              </div>
              <div id='card-recent-users'>
                <CardRecentUsers events={state.recentUsers} />
              </div>
            </Col>
          </Row>
          <FloatButton.BackTop />
        </Content>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
