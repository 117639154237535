import { Card, Space } from 'antd';
import { Typography } from 'antd';
const { Text } = Typography;
import React from 'react';
import { Username } from '../fragments/Username.jsx';

const CardTopInfo = ({ recentAction, responseTime }) => {
  if (recentAction.length === 0) {
    return null;
  }
  const average = (array) => array.reduce((a, b) => a + b) / array.length;
  const seconds = responseTime.map((e) => {
    const a = e.diff.split(':');
    return +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
  });
  const resp = seconds.length > 0 ? average(seconds).toFixed(3) + 's' : '--';
  const min = recentAction[0].minutes_since_last_event;
  const hoursAndMin =
    min > 60 ? `${Math.floor(min / 60)}h ${min % 60}m` : `${min}m`;
  return (
    <div className='site-card-border-less-wrapper'>
      <Card
        bordered={true}
        style={{
          width: 650,
          height: 70,
        }}
      >
        <Space size='middle'>
          <Username user={recentAction[0].username} />
          <Text style={{ color: '#8c8c8c' }}>Seen {hoursAndMin} ago</Text>{' '}
          <Text>
            Response time (last 2 hrs):{' '}
            <span style={{ fontStyle: 'italic' }}>{resp}</span>
          </Text>
        </Space>
      </Card>
    </div>
  );
};

export default CardTopInfo;
