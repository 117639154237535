export const MARGIN = {
  top: 15,
  right: 15,
  left: 0,
  bottom: 15,
};
export const BAR_MARGIN = {
  top: 15,
  right: 15,
  left: 0,
  bottom: 35,
};
