import { Card } from 'antd';
import { Bar, BarChart, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';
import { BAR_MARGIN } from './chartConfig';

const CardPerFilter = ({ events }) => (
  <div className='site-card-border-less-wrapper'>
    <Card
      title='Filter usage'
      bordered={true}
      style={{
        width: 650,
      }}
    >
      <BarChart width={600} height={300} data={events} margin={BAR_MARGIN}>
        <CartesianGrid strokeDasharray='3 3' />
        <Bar type='monotone' dataKey='count_events' stroke='#8884d8' />
        <XAxis dataKey='filter' angle='60' tickMargin='25' />
        <YAxis
          label={{
            value: 'Usage count',
            angle: -90,
            position: 'insideLeft',
          }}
        />
        <Tooltip />
      </BarChart>
    </Card>
  </div>
);

export default CardPerFilter;
