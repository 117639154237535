// TODO: use the same component for CardPerHour and CardPerDay
import { Card } from 'antd';
import {
  ComposedChart,
  Line,
  Bar,
  Scatter,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from 'recharts';
import { MARGIN } from './chartConfig';

const CardPerDay = ({ events }) => (
  <div className='site-card-border-less-wrapper'>
    <Card
      title='Traffic per day'
      bordered={true}
      style={{
        width: 650,
      }}
    >
      <ComposedChart width={600} height={300} data={events} margin={MARGIN}>
        <CartesianGrid strokeDasharray='3 3' />
        <Line
          yAxisId='left'
          type='monotone'
          dataKey='count_events'
          stroke='#8884d8'
        />
        <Scatter yAxisId='right' dataKey='count_distinct_users' />
        <XAxis
          dataKey='day'
          angle='60'
          tickMargin='20'
          label={{
            value: 'date',
            angle: '60',
            position: 'insideLeft',
            offset: -50,
          }}
        />
        <YAxis
          yAxisId='left'
          label={{
            value: 'Actions per day',
            angle: -90,
            position: 'insideLeft',
          }}
          type='number'
          orientation='left'
          dataKey='count_events'
        />
        <YAxis
          yAxisId='right'
          label={{
            value: 'Distinct users per day',
            angle: -90,
            position: 'insideTopRight',
          }}
          type='number'
          orientation='right'
          dataKey='count_distinct_users'
        />
        <Tooltip />
      </ComposedChart>
    </Card>
  </div>
);

export default CardPerDay;
