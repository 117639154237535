import { Card, Table } from 'antd';
import React from 'react';

const CardLastDays = ({ lastDays }) => {
  if (lastDays === undefined) {
    return null;
  }
  const columns = [
    {
      title: 'Day',
      dataIndex: 'day_name',
      key: 'day_name',
    },
    {
      title: 'No. of actions',
      dataIndex: 'count_events',
      key: 'count_events',
    },
    {
      title: 'No. of models seen',
      dataIndex: 'count_models_seen',
      key: 'count_models_seen',
    },
    {
      title: 'No. of distinct users',
      dataIndex: 'count_distinct_users',
      key: 'count_distinct_users',
    },
    {
      title: 'Sum amount of clicks',
      dataIndex: 'sum_amount',
      key: 'sum_amount',
      render: (text) => <div key={text}>${text}</div>,
    },
  ];

  return (
    <div className='site-card-border-less-wrapper'>
      <Card
        title="Last days' stats"
        bordered={true}
        style={{
          width: 650,
        }}
      >
        <Table
          columns={columns}
          dataSource={lastDays}
          rowKey={(r) => r.day_name}
          pagination={false}
        />
      </Card>
    </div>
  );
};

export default CardLastDays;
