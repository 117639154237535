import React from 'react';
import { telegramLink } from '../utils.js';
import { Typography } from 'antd';
const { Text, Link } = Typography;

export const Username = ({ user }) =>
  user.startsWith('@') ? (
    <Text>
      <Link href={telegramLink(user)}>{user}</Link>
    </Text>
  ) : (
    <Text>
      <span key={user}>{user}</span>
    </Text>
  );
